Object.defineProperty(exports, '__esModule', {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.putVal = 'PUT';
exports.exampleAPiEndPoint = 'EXAMPLE_URL';
exports.exampleAPiMethod = 'POST';
exports.exampleApiContentType = 'application/json';
exports.textInputPlaceHolder = 'Enter Text';
exports.labelTitleText = 'settings2';
exports.labelBodyText = 'settings2 Body';
exports.btnExampleTitle = 'CLICK ME';

exports.logoAlt = 'Pocket dispatcher logo';
exports.navDashboard = 'Dashboard';
exports.navLoads = 'Loads';
exports.navMessages = 'Messages';
exports.navNotifications = 'Notifications';
exports.avatarUrl =
  'https://s3-alpha-sig.figma.com/img/1e7b/e24d/ea8a5ebe20e9f24d1cfe69a425330f80?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XSUm4Q6kJa7TYtuayFOreaNDnuC5I28uO49LF4ulqNpe014Vb9ckeAHhh1g8IO64Rsrqwsm9BIoBDVDyOQTqn1AEpZMeCdsAV2jjldpKyf0AXkMt8LJasBvj2ltdyG4Id-QIpmya9gCczbzaHjSXd-zUXuh--94HQtUo49yGfDuM0S2WrjMMPAXifLPYOuMThJNeiJt-~pSG3EOpjkPfSPaB4yUJq4EcVm4eiSluEVBMo283tppuKePMKoLp7E4SrTAOXzCkofBPVu5AildwBEiSGuGHFbxMpj9oTEpJOOWLbG2u866lmNdy3lS4esRZ72ZB755j6sOXZYpAvSPqtA__';
exports.avatarUrlAlt = 'User image';
// Customizable Area End
