Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.btnExampleTitle = "Submit";
exports.invoiceValidation ="Please enter 6 Digit Invoice Number"
exports.enterInvoice="Invoice Number"
exports.invoiceNumber="Enter Invoice Number (for example 299206)"
exports.keyboardType="phone-pad"
exports.exampleAPiEndPoint = "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_invoice/invoice/generate_invoice_pdf?invoice_number=";
exports.exampleAPiMethod = "GET";

exports.powerText = "Power of Attorney";
exports.postAttornyPath = '/bx_block_invoice/power_of_attorneies?agreement=true';
exports.StatesName = [
  { code: "AL", name: "Alabama" },
  { code: "AK", name: "Alaska" },
  { code: "AZ", name: "Arizona" },
  { code: "AR", name: "Arkansas" },
  { code: "CA", name: "California" },
  { code: "CO", name: "Colorado" },
  { code: "CT", name: "Connecticut" },
  { code: "DE", name: "Delaware" },
  { code: "FL", name: "Florida" },
  { code: "GA", name: "Georgia" },
  { code: "HI", name: "Hawaii" },
  { code: "ID", name: "Idaho" },
  { code: "IL", name: "Illinois" },
  { code: "IN", name: "Indiana" },
  { code: "IA", name: "Iowa" },
  { code: "KS", name: "Kansas" },
  { code: "KY", name: "Kentucky" },
  { code: "LA", name: "Louisiana" },
  { code: "ME", name: "Maine" },
  { code: "MD", name: "Maryland" },
  { code: "MA", name: "Massachusetts" },
  { code: "MI", name: "Michigan" },
  { code: "MN", name: "Minnesota" },
  { code: "MS", name: "Mississippi" },
  { code: "MO", name: "Missouri" },
  { code: "MT", name: "Montana" },
  { code: "NE", name: "Nebraska" },
  { code: "NV", name: "Nevada" },
  { code: "NH", name: "New Hampshire" },
  { code: "NJ", name: "New Jersey" },
  { code: "NM", name: "New Mexico" },
  { code: "NY", name: "New York" },
  { code: "NC", name: "North Carolina" },
  { code: "ND", name: "North Dakota" },
  { code: "OH", name: "Ohio" },
  { code: "OK", name: "Oklahoma" },
  { code: "OR", name: "Oregon" },
  { code: "PA", name: "Pennsylvania" },
  { code: "RI", name: "Rhode Island" },
  { code: "SC", name: "South Carolina" },
  { code: "SD", name: "South Dakota" },
  { code: "TN", name: "Tennessee" },
  { code: "TX", name: "Texas" },
  { code: "UT", name: "Utah" },
  { code: "VT", name: "Vermont" },
  { code: "VA", name: "Virginia" },
  { code: "WA", name: "Washington" },
  { code: "WV", name: "West Virginia" },
  { code: "WI", name: "Wisconsin" },
  { code: "WY", name: "Wyoming" },
  { code: "DC", name: "District of Columbia" },
  { code: "AS", name: "American Samoa" },
  { code: "GU", name: "Guam" },
  { code: "MP", name: "Northern Mariana Islands" },
  { code: "PR", name: "Puerto Rico" },
  { code: "UM", name: "United States Minor Outlying Islands" },
  { code: "VI", name: "Virgin Islands, U.S." }
]
exports.examplePOSTAPiMethod = "POST";
exports.rule1 = "I,	hereby appoint “24/7 TRUCK DISPATCH SERVICE” of “2403 NORTH BROADWAY BLVD SUITE 2 LOS ANGELES CA 90031”, as my Attorney-in-Fact (“Aqent”fl."
exports.rule2 = "“24/7 TRUCK DISPATCH SERVICE” aqents shall have full power and authority to act on my behalf. This power and authority shall authorize “24/7 TRUCK DISPATCH SERVICE” to manaqe and conduct affairs and to exercise all of my leqal riqhts and powers includinq all riqhts and powers that I may acquire in the future. “24/7 TRUCK DISPATCH SERVICE”powers shall include, but not be limited to, the power to:";
exports.list1 = "Contact shippers and brokers on my behalf for cargo."
exports.list2 = "Transfer of Paperwork (Carrier Packet, Rate Confirmations, Insurance Certificates, Invoices and all necessary paperwork) to shippers."
exports.list3 = "Sign and Execute Rate Confirmations for freight on my behalf"
exports.rule3 ="This Power of Attorney shall be construed broadly as a General Power of Attorney. The listing of Specific powers is not intended to limit or restrict the general powers granted in this Power of Attorney in any manner."
exports.rule4 ="“24/7 TRUCK DISPATCH SERVICE” shall not be liable for any loss that results from a judgment error that was made in good faith. However, “24/7 TRUCK DISPATCH SERVICE” shall be liable for willful misconduct or the failure to act in good faith while acting under the authority of this Power of Attorney."
exports.rule5 ="I authorize my Agent to indemnify and hold harmless any third party who accepts and acts under This document. “24/7 TRUCK DISPATCH SERVICE” shall be entitled to reasonable compensation for any services provided as my Agent. “24/7 TRUCK DISPATCH SERVICE” shall be entitled to reimbursement of all reasonable expenses incurred in connection with this Power of Attorney. “24/7 TRUCK DISPATCH SERVICE” shall provide an accounting for all acts performed as my Agent, if I so request or if such a request is made by any authorized personal representative or fiduciary acting on my behalf."
exports.rule6 ="This Power of Attorney shall become effective immediately and shall not be affected by my disability or lack of mental competence, except as may be provided otherwise by an applicable state statute. This is a Durable Power of Attorney. This Power of Attorney shall continue effective for (24 Months). This Power of Attorney may be revoked by me at any time by providing (30 Days) written notice to my Agent."
exports.Agreement = "Agreement"
exports.title = "Dispatcher - Carrier Agreement"
exports.agreementIntro = "This Agreement is made on"
exports.dateLabel = "by"
exports.obligationHeader = "Obligations of Pocket Dispatcher App:"
exports.obligation1 = "Pocket Dispatcher App will work as a platform to connect load brokers, shippers and carriers to find carriers loads."
exports.obligation2 = "Pocket Dispatcher App carries no financial or legal responsibility in the transaction between the shipper, carrier agreement."
exports.carrierObligationHeader = "Obligations of Carrier:"
exports.carrierObligation1 = "Carrier give authority for Pocket Dispatcher to collect a dispatch fee for every load found on the app. Failure to pay the fee for services rendered will result in termination of contract and services immediately."
exports.carrierSignatureLabel = "Carrier Signature:"
exports.Carrier = "Carrier"
exports.appName = "Pocket Dispatcher App"
exports.Shippers = "Shippers"
// Customizable Area End