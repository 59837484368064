// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import ICalendarSupport from "../../blocks/icalendarsupport/src/ICalendarSupport";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Notifications from "../../blocks/notifications/src/Notifications";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPasswordBlock from "../../blocks/email-account-login/src/ForgotPasswordBlock.web";
import OtpPage from "../../blocks/email-account-login/src/OtpPage.web";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Maps from "../../blocks/maps/src/Maps";
import Geofence from "../../blocks/geofence/src/Geofence";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ResetPassword from "../../blocks/email-account-login/src/ResetPassword.web";
import OtpRegistrationPage from "../../blocks/email-account-registration/src/OtpRegistrationPage.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web";
import LoadBrokerSearch from "../../blocks/advancedsearch/src/LoadBrokerSearch.web";
import BrokerLoads from "../../blocks/multilevelapproval/src/MyLoads.web";
import LoadBoardListView from "../../blocks/advancedsearch/src/LoadBoardListView.web";
import EmailNotification from "../../blocks/settings2/src/EmailNotification.web"
import Hyperlinks from "../../blocks/navigationmenu/src/Hyperlinks.web"
import TermsAndCon from "../../blocks/settings2/src/TermCondition.web"
import TruckerDetails from "../../blocks/email-account-registration/src/TruckerDetails.web"
import MyLoads from "../../blocks/multilevelapproval/src/MultilevelApproval.web"
import PushnotificationsWeb from "../../blocks/pushnotifications/src/Pushnotifications.web";
import Payment from '../../blocks/settings2/src/Payment.web';

const routeMap = {
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  ICalendarSupport: {
    component: ICalendarSupport,
    path: "/ICalendarSupport"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: "/Onboardingguide"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  Settings2: {
    component: Settings2,
    path: "/account/change-password"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/signup"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Location: {
    component: Location,
    path: "/Location"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/login"
  },
  ForgotPasswordBlock: {
    component: ForgotPasswordBlock,
    path: "/forgot-password"
  },
  OtpPage: {
    component: OtpPage,
    path: "/OtpPage"
  },
  ResetPassword: {
    component: ResetPassword,
    path: '/reset-password'
  },
  OtpRegistrationPage: {
    component: OtpRegistrationPage,
    path: '/OTP',
    exact: true
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  PostCreation: {
    component: PostCreation,
    path: "/broker/load/create"
  },
  PostCreationEdit: {
    component: PostCreation,
    path: "/broker/load/edit/:id"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews"
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  Maps: {
    component: Maps,
    path: "/Maps"
  },
  Geofence: {
    component: Geofence,
    path: "/Geofence"
  },
  Chat: {
    component: Chat,
    path: "/chat"
  },
  ViewChat: {
    component: ViewChat,
    path: "/ViewChat"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
ICalendarSupport:{
 component:ICalendarSupport,
path:"/ICalendarSupport"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Settings2:{
 component:Settings2,
path:"/account/change-password"},
EmailNotification:{
  component:EmailNotification,
  path:"/account/notifications"  
},
Hyperlinks:{
  component:Hyperlinks,
  path:"/account/hyperlinks"  
},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Notifications:{
 component:Notifications,
path:"/Notification"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/signup"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/login"},
ForgotPasswordBlock:{
  component:ForgotPasswordBlock,
  path:"/forgot-password"
},
OtpPage:{
  component:OtpPage,
  path:"/OtpPage"
},
ResetPassword:{
  component:ResetPassword,
  path:'/reset-password'
},
OtpRegistrationPage:{
  component:OtpRegistrationPage,
  path:'/OTP',
  exact:true,
},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
PostCreation:{
 component:PostCreation,
path:"/broker/load/create"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Maps:{
 component:Maps,
path:"/Maps"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Chat:{
 component:Chat,
path:"/chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  },
  TruckerDashboard: {
    component: Dashboard,
    path: "/trucker",
    exact:true,
  },
  BrokerDashboard: {
    component: Dashboard,
    path: "/broker",
    exact:true,
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/account',
    exact:true,
  },
  LoadBrokerSearch: {
    component: LoadBrokerSearch,
    path: "/broker/load-board/search",
    exact:true,
  },
  TruckerSearch: {
    component: LoadBrokerSearch,
    path: "/trucker/load-board/search",
    exact:true,
  },
  BrokerLoads: {
    component: BrokerLoads,
    path: "/broker/my-loads",
  },
  LoadBrokerListView: {
    component: LoadBoardListView,
    path: "/broker/load-board/results",
    exact: true,
  },
  TruckerListView: {
    component: LoadBoardListView,
    path: "/trucker/load-board/results",
    exact: true,
  },
  TermsAndCon:{
    component:TermsAndCon,
    path:"/terms-and-conditions"
  },
  TruckerDetails:{
    component:TruckerDetails,
    path:"/trucker-details"
  },
  MyLoads:{
    component :MyLoads,
    path:"/trucker/my-loads"
  },
  PushnotificationsWeb:{
    component:PushnotificationsWeb,
    path:'/notifications'
  },
  Payment:{
    component: Payment,
    path: '/trucker/payment-method'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;