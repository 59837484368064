import React from 'react';

// Customizable Area Start

import { styled } from '@mui/material/styles';
import { mastercard, visa } from './assets';
import LayoutSettings from './LayoutSettings.web';
import PaymentController, { Props } from './PaymentController';

// Customizable Area End

export default class Payment extends PaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LayoutSettings view="dashboard">
        <StyledDiv data-test-id="payment-method">
          <h1 className="title">Payment Method</h1>
          <section className="payments">
            <ul className="methods-list">
              <li data-selected>
                <div className="type">
                  <img src={mastercard} alt="mastercard" />
                  <span>Master card</span>
                </div>

                <div className="exp-date">
                  Expiry date: <span>11/19</span>
                </div>

                <div className="number">
                  Card number: <span>**** 1745</span>
                </div>

                <button className="actions">
                  <span>Primary</span>
                  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm-2 8c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </li>
              <li>
                <div className="type">
                  <img src={visa} alt="visa" />
                  <span>Visa card</span>
                </div>
                <div className="exp-date">
                  Expiry date: <span>09/26</span>
                </div>

                <div className="number">
                  Card number: <span>**** 5292</span>
                </div>

                <button className="actions">
                  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm-2 8c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </li>
            </ul>
            <button className="add-new">
              <span>➕ </span>ADD NEW CARD
            </button>
          </section>
        </StyledDiv>
      </LayoutSettings>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledDiv = styled('div')`
  .title {
    color: #215089;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.005em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .payments {
    padding: 24px 30px;
    background-color: white;
    border-radius: 8px;

    display: grid;
    gap: 32px;

    .methods-list {
      margin: 0;
      padding: 0;
      display: grid;
      gap: 24px;

      li {
        list-style: none;
        border-radius: 8px;
        padding: 26px 20px;
        border: 1px solid #d6d3d1;
        border-left: 4px solid #d6d3d1;

        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 96px;
        grid-template-areas: 'type exp numb actions';
        gap: 8px;

        &[data-selected='true'] {
          border-left: 4px solid #059669;
        }

        .type {
          display: flex;
          gap: 8px;
          font-weight: 700;
          color: #0f172a;
          grid-area: type;
        }
        .exp-date,
        .number {
          color: #1e293b;
          span {
            font-variant-numeric: tabular-nums;
            color: #64748b;
          }
        }
        .exp-date {
          grid-area: exp;
        }
        .number {
          grid-area: numb;
        }

        .actions {
          grid-area: actions;
          border: none;
          background-color: transparent;
          font-family: inherit;
          color: #64748b;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
          span {
            color: #059669;
            font-weight: 700;
          }
        }
      }
      @media (width < 768px) {
        li {
          grid-template-columns: 1fr 1fr 1fr 96px;
          grid-template-areas: 'type type type actions' 'exp exp exp exp' 'numb numb numb .';
        }
      }
    }

    .add-new {
      border: 1px solid #d97706;
      background-color: #f59e0b;
      color: #fffbeb;
      padding: 10px 16px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
      justify-self: end;
      display: flex;
      gap: 8px;
    }
  }
`;
// Customizable Area End
