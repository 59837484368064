import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start

// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    // TODO: controller logic: get payment-methods list from API
    /*
      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceDataMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {};
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);    
    */

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // runEngine.debugLog('Message Recived', message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // async componentDidMount() {
  //   super.componentDidMount();
  // }

  // Customizable Area End
}
